/**
 * Homepage
 **/

.intro{
	padding-top: 4rem;
	padding-bottom: 4rem;
	background-color: $primary-lightest;
	color: $primary-color;
	position: relative;

	h1{
		font-size: 20px;
		line-height: 32px;
	}

	&:after{
		background-image: url('images/background-triangle.svg');
		content: '';
		position: absolute;
	    right: 0;
	    bottom: -20px;
	    height: 400px;
	    width: 336px;
	    background-repeat: no-repeat;
	}	

	@media (max-width: 575.98px) {
		&:after{
	    	bottom: -20px;
	    	width: 120px;
		}
	}

	&--home{
		strong{
			color: $secondary-color;
		}

		.brand{
			font-weight: 700;

			span{
				color: $secondary-color;
			}
		}
	}

	&--sub{
		h1{
			font-weight: 700;
			font-size: 30px;
		}

		p{
			font-size: 18px;

			strong{
				color: $secondary-color;
				font-weight: 500;
			}
		}
	}
}

.main-content{
	margin-top: 6rem;
	margin-bottom: 6rem;

	&__item{
		margin-bottom: 2.5rem;

		h3{
			font-size: 19px;
			font-weight: 600;
			color: #FFFFFF;
			letter-spacing: 0;
			text-shadow: 0 2px 20px rgba(0,0,0,0.50);
			position: relative;
			margin-bottom: 2rem;		

			&:after{
				content:'';
				position: absolute;
				height: 2px;
				width: 60px;
				background-color: transparent;
				bottom: -15px;
				left: 0;
				transition: all .2s ease-in-out;	
			}
		}

		&:before {
		    content:"\A";
		    border-style: solid;
		    border-width: 0px 47px 45px 0;
		    border-color: transparent $white transparent transparent;
		    position: absolute;
		    right: 15px;
		    z-index: 2;
		}		
	}

	.item-wrapper{
		padding: 2.5rem;
		position: relative;
		padding-bottom: 150px;
		transition: all .4s ease-in-out;
		height: 100%;

		p{
			opacity: 0;
			transition: all .3s ease-in-out;
		}

		&:hover{
			background-color: $secondary-color;	

			p{
				opacity: 1;
			}

			.item-logo{
				opacity: 1;
			}

			h3{
				&:after{
					background-color: $primary-color;
					width: 120px;
				}
			}
		}
	}

	.overlay{
		background-image: url('https://placehold.it/500x500');
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		width: 100%;
		height: 100%;
		bottom:0;
		left: 0;
		z-index: -1;
		overflow:hidden;
	}

	.item-logo{
		max-height: 100%;
		width: 100%;
		opacity: 0;
		transition: all .2s ease-in-out;
	}

	p{
		color: $white;
		margin-bottom: 1.5rem;
	}

	.button--primary{
		position: absolute;
		right: 20px;
		bottom: 25px;
	}
}


.call-to-action{
	background-color: $secondary-color;
	padding-top: 3rem;
	padding-bottom: 3.5rem;

	h4{
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 2.5rem;

		strong{
			color: $primary-color;
			font-weight: 600;
		}
	}
}