footer{
	background-color: $primary-lightest;
}

.footer-primary{
	padding-top: 3rem;
	padding-bottom: 2rem;

	ul{
		list-style-type: none;
		padding-left: 0;
	}

	ul li,
	ul li a,
	p{
		color: #6B6B6B;
	}

	h5{
		margin-bottom: 1.5rem;
	}
}

footer.footer-copyright{
	background-color: $primary-color;
	padding-top: 15px;

	span,
	a{
		color: $white;
		text-align: center;
	}

	span,
	p{
		font-size: 12px;
	}
}

.icon-linkedin{
	max-width: 35px;
}