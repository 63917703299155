/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;

		transition: all .4s ease-in-out;
	
		&.collapse.in{
			display: inherit !important;

		    position: absolute;
		    width: 100%;
		    top: 160px;
		    left: 0;
		    /* height: 100%; */
		    background-color: #90b42e;
		    z-index: 100;
		    margin-left: 0;	

		    .navbar-nav{
		    	text-align: center;
		    	width: 100%;
		    }

		    .nav-link{
		    	color: $white;
		    	border-bottom: 0
		    }
		}
	}
}

/**
 * Navbar
 **/

#nav-main{
	height: 120px;
	padding-left: 0;
}

.navbar-brand{
	height: auto !important;
	padding-left: 0 !important;
}

.navbar-collapse{
	margin-left: 75px;
}

.navbar-light .navbar-nav .current-menu-item .nav-link{
	color: $primary-color;
	background-color: $primary-lightest;
	border-bottom: 2px solid $primary-color;
}

.navbar-light .navbar-nav .nav-link{
	color: $secondary-color;
	border-bottom: 2px solid $white;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 35px;
    font-size: 13px;
    transition: all .2s ease-in-out;


	&:hover{
		color: $primary-color;
		background-color: $primary-lightest;
		border-bottom: 2px solid $primary-color;
	}
}

/**
 * Search
 **/
.searchform{
	input{
		border: 2px solid $primary-color;				
    	color: $primary-color;
    	font-weight: 600;
    	padding: 10px 20px;
    	transition: all .4s ease-in-out;
    	width: 200px;
    	position: relative;
    	z-index: 100;

    	&:focus{
    		//border: 2px solid $secondary-color;
    		color: $primary-color;
    		font-weight: 600;
    		outline: 0;
    		box-shadow: none;
    	}
	}

	&:after{

	}

	&:before{
		content: "\A";
    	border-style: solid;
		border-width: 0px 15px 14px 0;
	    border-color: transparent $primary-color transparent transparent;
	    position: absolute;
	    right: 16px;
	    z-index: 201;
	    top: 35px;
	}

	&:after{
		content: "\A";
    	border-style: solid;
    	border-width: 0px 15px 14px 0;
    	border-color: transparent #fff transparent transparent;
    	position: absolute;
    	right: 13px;
    	z-index: 202;
    	top: 35px;	
	}
}

.search-submit{
	background-image: url('images/search-icon.svg');
	background-size: 100%;
	background-repeat: no-repeat;
	height: 19px;
	width: 19px;
	display: inline-block;
    position: absolute;
    right: 35px;
    top: 50px;
    z-index: 200;
}