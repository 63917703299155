/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #442165;
$secondary-color: #93B633;

$primary-lightest: rgba(66, 31, 100, 0.05);

$white: #FFFFFF;
$black: #000000;

/**
 * Fonts
 **/

$primary-font: 'Open Sans', helvetica, arial, sans-serif;