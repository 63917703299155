/**
 * Subpage
 **/

.main-content--sub{
	h2{
		color: $secondary-color;
		font-size: 26px;
		font-weight: 700;
	}

	h3{
		color: $primary-color;
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 1.5rem;
	}

	p{
		color: $primary-color;
		margin-bottom: 2rem;
	}
}


/**
 * Search results
 **/

h2 a{
	color: $secondary-color;
}

.tease-page p a{
	color: $primary-color;
	font-weight: 600;
	text-decoration: underline;

	&:hover{
		color: $primary-color;
		text-decoration: none;
	}
}