/**
 * Typography
 **/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	font-family: $primary-font;
}

h2,
h3,
h4{
	color: $white;
}

h5{
	color: $secondary-color;
	font-size: 16px;
}

p{
	font-size: 16px;
	line-height: 32px;
}

ul{
	position: relative;
	padding-left: 15px;

	/*
	&:after{ 
		content: '';
		background-color: $primary-color;
		position: absolute;
		height: 5px;
		width: 5px;
		border-radius: 5px;
	}*/

	li{
		
	}

	li,
	li a{
		color: $primary-color;
	}
}

/**
 * Buttons
 **/

.button{
	&--primary{
		background-color: $primary-color;
		border: 2px solid $primary-color;
		color: $white;
		font-size: 14px;
		font-weight: 600;
		padding: 12px 37px;
		transition: all .2s ease-in-out;
		position: relative;

		&:hover{
			background-color: transparent;
			border: 2px solid $primary-color;
			color: $primary-color;
			text-decoration: none;

			&:before{
				content: "\A";
		    	border-style: solid;
				border-width: 0px 15px 14px 0;
			    border-color: transparent $primary-color transparent transparent;
			    position: absolute;
			    right: 0px;
			    z-index: 10;
			    top: -2px;
			}

			&:after{
				content: "\A";
		    	border-style: solid;
		    	border-width: 0px 15px 14px 0;
		    	border-color: transparent $secondary-color transparent transparent;
		    	position: absolute;
		    	right: -3px;
		    	z-index: 11;
		    	top: -2px;	
			}				
		}

		&.brochure{
			width: 144px;
    		bottom: 90px !important;			
		}
	
	}	

	&--secondary{
		background-color: $secondary-color;
		color: $white;
		font-size: 14px;
		font-weight: 600;
		padding: 16px 37px;

		&:hover{

		}
	}

	&--white{
		background-color: $white;
		border: 2px solid $white;
		color: $secondary-color;
		font-size: 16px;
		font-weight: 600;
		padding: 16px 37px;
		transition: all .2s ease-in-out;
		position: relative;

		&:hover{
			background-color: transparent;
			border: 2px solid $primary-color;
			color: $primary-color;
			text-decoration: none;

			&:before{
		    	border-color: transparent $primary-color transparent transparent;
			}
		}

		&:before{
			content: "\A";
	    	border-style: solid;
			border-width: 0px 15px 14px 0;
		    border-color: transparent $white transparent transparent;
		    position: absolute;
		    right: 0px;
		    z-index: 10;
		    top: -2px;
		    transition: all .2s ease-in-out;
		}

		&:after{
			content: "\A";
	    	border-style: solid;
	    	border-width: 0px 15px 14px 0;
	    	border-color: transparent $secondary-color transparent transparent;
	    	position: absolute;
	    	right: -3px;
	    	z-index: 11;
	    	top: -2px;	
		}					
	}
}