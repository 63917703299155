/**
 * Header
 **/

/**
 * Language widget
 **/
.language-widget{
	position: absolute;
	right: 180px;
	z-index: 2000;

	@include media-breakpoint-down(md){
		right: 10px;
	}

	ul{
		list-style-type: none;
	}

	li{
		list-style-type: none;
		display: inline-block;

		a{
		    color: $white;
		    padding: 5px;
		    background-color: $primary-color;
		    font-size: 12px;
		    font-weight: 600;
		    transition: all .2s ease-in-out;

		    &:hover{
		    	text-decoration: none;
		    	background-color: $secondary-color;
		    }	
		}
	}
}